<script setup>
  const searchQuery = inject('searchQuery');
  const emit = defineEmits(['closeClick']);

  // --

  const input = ref();

  function focus() {
    input.value.focus();

    const value = input.value.value;
    input.value.value = '';
    input.value.value = value;
  }

  defineExpose({ focus });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="input">
    <input ref="input" v-model="searchQuery" placeholder="What are you looking for" />
    <div class="buttons">
      <button type="submit" aria-label="search"><IconSearch /></button>
      <button @click="emit('closeClick')" aria-label="close"><IconClose /></button>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .input {
    position: relative;

    width: 100%;
    height: px(80);

    @include break-small {
      height: px(44);
    }
  }

  input {
    width: 100%;
    height: 100%;

    font-size: px(20);
    padding-left: px(32);

    border: none;
    outline: none;

    border-radius: px(2);
    background-color: $white;

    &::placeholder {
      color: $grey-3;
    }

    @include break-small {
      font-size: px(16);
      padding-left: px(16);
    }
  }

  .buttons {
    position: absolute;

    height: 100%;

    top: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: px(70);
    height: px(44);

    @include break-small {
      width: px(48);
      height: 100%;
    }

    &:last-child {
      border-left: px(1) solid $grey-4;
    }

    &:deep(path) {
      fill: $black;
      @include fade('fill', 0.37s);
    }

    @include hover {
      &:deep(path) {
        @include fade('fill', 0.15s);
        fill: $red;
      }
    }
  }
</style>
